@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville:400i|Open+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans',  'Libre Baskerville'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

html::-webkit-scrollbar {
  /* This is the magic bit for WebKit */
  display: none;
}

